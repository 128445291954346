.main{
    height: 100vh;
}

.profile-picture{
    width: auto;
    height: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: grayscale(1)
}

.main-margin{
    margin-top: 25%;
}

.info-container{
    display: flex;
    flex-direction: column;
    margin-top: 15%;
}

/* iPad Mostly */

@media only screen and (max-width: 768px){
    .profile-picture{
        height: 200px;
    }

    .info-container{
        margin-top: 5%;
    }

    .main-margin{
        margin-top: 30%;
    }
}

@media only screen and (min-width: 769px){
    .info-container{
        margin-left: 10%;
    }
}

/* iPhone 6 and earlier */

@media only screen and (max-width: 400px){
    .profile-picture{
        height: 200px;
    }

    .main-margin{
        margin-top: 20%;
    }

    .info-container{
        margin-top: 0;
    }
}