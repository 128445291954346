.experience{
    height: 200vh;
}

.card-skill{
    background-color: none;
    background: none;
    width: 80%;
    margin: 1%;
    margin-bottom: 10px;
}

.cards-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
}

.icon {
    width: 15%;
    height: auto;
    font-size: 2.5rem;
    padding-right: 2rem;
    color: white;
}

.icon-title{
    width: 15%;
    height: auto;
    font-size: 2.5rem;
    padding-right: 2rem;
    padding-top: 1%;
    color: rgba(255, 255, 255, 0.6);
}

.card h2{
    color: white;
}

.skill-level{
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
}

.skill-title{
    font-size: 35px;       
    font-weight: bold;
}

.skill-type{
    color: orange;
    font-weight: bold;
}

.skill-description{
    height: 10vh;
    text-align: left;
    font-weight: bold;
    font-size: larger;
}

.card-separator{
    border-bottom-width: 3px;
    border-bottom-style: solid;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.company-picture{
    width: auto;
    height: 100px;
    margin-top: 10%;
}

.experience-card{
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 5%;
}
.experience-date {
    margin-top: 5px;
    margin-left: 5px; 
    color: orange;
    margin-bottom: 0;
}

.experience-position{
    margin: 0;
    padding: 0;
    font-size: small;
    font-style: italic;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.experience-description{
    text-align: justify;
}

.icons-grid{
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;
}

.anchor-education{
    margin-left: 80%;
    margin-top: 5%;
}

@media only screen and (max-width: 500px){
    .cards-grid{
        grid-template-columns: repeat(1, 1fr);
    }

    .experience{
        height: 100%;
    }

    .company-picture{
        margin-top: 0;
        margin-left: 10%;
        margin-bottom: 25px
    }
    
    .icons-grid{
        width: 50vh;
    }

    .skill-description{
        height: 100%;
    }

    .company-picture{
        margin-top: 1%;
    }

    .skill-title{
        margin-left: 10%;
        margin-top: 5%;
        font-size: 20px;
        width: 100%;
    }

    .anchor-education{
        margin-left: 50%;
    }
}

@media only screen and (min-width: 1366px){
    .company-picture{
        /* margin-top: 75%; */
        height: 85px;
    }
}


@media only screen and (max-width: 500px){

    .experience{
        height: 100%;
    }

    .skill-title{
        margin-left: 10px;
    }
    
}
