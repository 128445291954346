.education{
    height: 100vh;
}

.education-picture{
    width: auto;
    height: 80px;
    margin-bottom: 10%;
}

.anchor-projects{
    margin-left: 90%;
    margin-top: 5%;
}
@media only screen and (max-width: 500px){
    .education{
        height: 100%;
    }

    .anchor-projects{
        margin-left: 70%;
    }
}
