.about{
    height: 100vh;
}

.card{
    background-color: none;
    background: none;
    width: 90%;
    margin: 1%;
}

.cards{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;
}

.about-me{
    width: 100%;
    text-align: justify;
    margin-top: 5%;
}

.card-body{
    margin: 0;
    padding: 0;
}


.card-animated :hover{
    transform: scale(1.1);
    transition: transform .5s;

}

.cards :hover .images-desc{
    display: none;
}

.images-desc{
    color: rgba(255, 255, 255, 0.9);
    /* font-weight: bold; */
    font-style: oblique;
    text-align: left;
}

@media only screen and (max-width: 500px){
    .cards{
        grid-template-columns: repeat(1, 1fr);
    }

    .about{
        height: 100%;
    }
    
}
