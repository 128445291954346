.float-btn {
    position: fixed;
    width: 60px;
    height: 60px;  
    background-color: orange;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 3px 3px 5px rgba(255, 255, 255, 0.25);
    z-index: 100;
    bottom: 15px;
    right: 15px;
  }
  
  .float-icon{
    margin-top: 13px;
  }