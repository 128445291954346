.projects{
    height:100vh;
}

.project-image{
    width: 300px;
    height: 250px;
    object-fit: scale-down;
}

.project-card{
    border-bottom-color:rgba(255, 255, 255, 0.3);
    border-width: 1px;
}

.project-company{
    font-style: italic;
    margin-top: 0%;
    color: rgba(255, 255, 255, 0.3);
}

.project-title{
    font-size: 25px;
    margin: 0;
    font-weight: bold;
}

.project-description{
    text-align: justify;
    margin-top: 1%;
    width: 100%;
    margin-left: 1%;
}

.link-icon{
    margin-left: 5px;
}

@media only screen and (max-width: 1367px){
    .project-image{
        height: 100px;
        width: 300px;
    }
}


@media only screen and (max-width: 500px){
    .project-image{
        height: 100px;
        width: 170px;
    }

    .projects{
        height: 100%;
    }
}
