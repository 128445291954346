html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body{
  background-color: black;
  font-family:  'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

p{
  color: white;
}

h1{
  color: white;
  font-weight: bold;
}

h4{
  color: white;
  font-weight: bold;
}

a{
  color: orange;
  font-weight: bold;
}

.row{
  width: 100%;
  max-width: 100vh;
  margin-left: auto;
  margin-right: auto;
}
